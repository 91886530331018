import React from 'react';
import { Heading, Icon, Link, Text } from 'bsd-react-ui-kit';

import useTrackPage from 'modules/analytics/useTrackPage';

// constants
import { APP_PATHS } from 'routes/constants';
import * as constants from 'containers/Error/GeneralError/constants';

export default function GeneralError() {
  const baseClass = 'bsd-general-error';

  useTrackPage(constants.GENERAL_ERROR_HEADING);

  return (
    <div className={baseClass} role="alert">
      <div className={`${baseClass}-image`}>
        <Icon
          description="Alert Icon"
          modifier="error"
          name="circle-alert"
          size="xl"
        />
      </div>
      <div className={`${baseClass}-content`}>
        <Heading
          className={`${baseClass}-heading`}
          element="h1"
          modifier="bold"
          size="l"
        >
          {constants.GENERAL_ERROR_HEADING}
        </Heading>
        <Text className={`${baseClass}-text`} element="p">
          {constants.GENERAL_ERROR_BEGINNING_TEXT}{' '}
          <Link modifier="anchor" to={APP_PATHS.enterAccountNumber}>
            {constants.GENERAL_ERROR_LINK_TEXT}
          </Link>{' '}
          {constants.GENERAL_ERROR_MIDDLE_TEXT}{' '}
          <Link external modifier="anchor" to="tel:+18449630187">
            (844) 963-0187
          </Link>{' '}
          {constants.GENERAL_ERROR_ENDING_TEXT}
        </Text>
      </div>
    </div>
  );
}
