import React from 'react';
import { useLocation } from 'react-router-dom';
import { Heading, Link, Text } from 'bsd-react-ui-kit';
import { APP_PATHS } from 'routes/constants';

import useTrackPage from 'modules/analytics/useTrackPage';

import * as constants from 'containers/AccountNotEligible/constants';

export default function AccountNotEligible() {
  const baseClass = 'bsd-account-not-eligible';
  const location = useLocation();
  const { isWalledGarden } = location?.state;

  useTrackPage(constants.NOT_ELIGIBLE_HEADING);

  return (
    <div className={baseClass}>
      <Heading
        className={`${baseClass}-heading`}
        element="h1"
        modifier="bold"
        size="l"
      >
        {constants.NOT_ELIGIBLE_HEADING}
      </Heading>
      <Text className={`${baseClass}-subheading`} element="p" size="l">
        {constants.NOT_ELIGIBLE_TEXT}
      </Text>
      <Link
        className={`${baseClass}-button`}
        modifier="primary"
        standard
        to={
          isWalledGarden ? APP_PATHS.walledGarden : APP_PATHS.enterAccountNumber
        }
        type="button"
      >
        {constants.BUTTON_TEXT}
      </Link>
    </div>
  );
}
