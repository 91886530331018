export const getIsDev = () => window.location.hostname === 'localhost';

export const getIsNonProd = () =>
  window.location.hostname ===
  'device-activation.np.digital.business.comcast.com';

export const getIsProd = () =>
  window.location.hostname === 'business.comcast.com' ||
  window.location.hostname === 'device-activation.digital.business.comcast.com';

export const getAppBasePath = () =>
  window.location.hostname === 'business.comcast.com' ||
  window.location.hostname === 'business.stg.comcast.com'
    ? '/activate'
    : '';
