import { getIsDev, getIsProd, getIsNonProd } from 'utils/config/util';
import devConfig from 'config/dev';
import nonProdConfig from 'config/nonProd';
import prodTestConfig from 'config/prodTest';
import prodConfig from 'config/prod';

export const getConfig = () => {
  const isDev = getIsDev();
  const isNonProd = getIsNonProd();
  const isProd = getIsProd();

  switch (true) {
    case isDev:
      return devConfig;
    case isNonProd:
      return nonProdConfig;
    case isProd:
      return prodConfig;
    default:
      return prodTestConfig;
  }
};

export default getConfig();
