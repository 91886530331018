import React from 'react';
import { Heading, Link, Text } from 'bsd-react-ui-kit';
import { COMCAST_BUSINESS_PATHS } from 'routes/constants';

import useTrackPage from 'modules/analytics/useTrackPage';

import * as constants from 'containers/Error/PageNotFound/constants';

export default function PageNotFound() {
  const baseClass = 'bsd-page-not-found';

  useTrackPage(constants.PAGE_NOT_FOUND_HEADING);

  return (
    <div className={baseClass}>
      <Heading
        className={`${baseClass}-heading`}
        element="h1"
        modifier="bold"
        size="l"
      >
        {constants.PAGE_NOT_FOUND_HEADING}
      </Heading>
      <Text className={`${baseClass}-subheading`} element="p" size="xl">
        {constants.PAGE_NOT_FOUND_TEXT}
      </Text>
      <div className={`${baseClass}-content`}>
        <Text className={`${baseClass}-text`} element="p">
          {constants.PLEASE_VISIT_TEXT}
          <Link standard to={COMCAST_BUSINESS_PATHS.account}>
            {constants.MY_ACCOUNT_TEXT}
          </Link>
          {constants.HOMEPAGE_TEXT}
          <Link external to={COMCAST_BUSINESS_PATHS.helpAndSupport}>
            {constants.HELP_AND_SUPPORT_TEXT}
          </Link>{' '}
          {constants.LOOKING_FOR_TEXT}
        </Text>
      </div>
    </div>
  );
}
