import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FlowHeader from 'containers/Layout/FlowHeader';

export default function Layout({
  component: Component,
  pageTitle,
  isWalledGarden,
}) {
  const baseClass = 'bsd-layout';

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <div className={baseClass} id="content">
      <FlowHeader />
      <div className="bsd-content container">
        <Component isWalledGarden={isWalledGarden} />
      </div>
    </div>
  );
}

Layout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isWalledGarden: PropTypes.bool,
  pageTitle: PropTypes.string,
};

Layout.defaultProps = {
  pageTitle: null,
};
