import React from 'react';

import { Heading, Icon } from 'bsd-react-ui-kit';
import { COMCAST_BUSINESS_PATHS } from 'routes/constants';

export const HEADER_TEXT = 'Activate Your Internet Service';

export default function FlowHeader() {
  const baseClass = 'bsd-flow-header';

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-container`}>
        <a
          className={`${baseClass}-logo`}
          data-testid="comcast-business-logo"
          href={COMCAST_BUSINESS_PATHS.home}
        >
          <Icon
            description="Comcast Business"
            height={38}
            name="bsp-logo"
            width={100}
          />
        </a>

        <Heading
          className={`${baseClass}-heading`}
          element="h1"
          modifier="bold"
        >
          {HEADER_TEXT}
        </Heading>
      </div>
    </div>
  );
}
