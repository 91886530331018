import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { APP_PATHS } from 'routes/constants';
import Layout from 'containers/Layout/Layout';
import GeneralError from 'containers/Error/GeneralError/GeneralError';
import PageNotFound from 'containers/Error/PageNotFound/PageNotFound';
import AccountNotEligible from 'containers/AccountNotEligible/AccountNotEligible';

export const Landing = lazy(() =>
  import(/* webpackChunkName: "landing" */ 'containers/Landing/Landing')
);

const RedirectToEnterAccountNumber = () => (
  <Redirect to={APP_PATHS.enterAccountNumber} />
);

const routes = [
  {
    path: APP_PATHS.basePage,
    exact: true,
    layout: Landing,
    component: RedirectToEnterAccountNumber,
  },
  {
    path: APP_PATHS.enterAccountNumber,
    exact: true,
    layout: Layout,
    component: Landing,
    pageTitle: 'Activate Your Internet Service',
  },
  {
    path: APP_PATHS.accountNotEligible,
    layout: Layout,
    component: AccountNotEligible,
    pageTitle: 'Account Not Eligible',
  },
  {
    path: APP_PATHS.enterAccountNumberError,
    exact: true,
    layout: Layout,
    component: GeneralError,
    pageTitle: 'Sorry, Something Went Wrong',
  },
  {
    path: APP_PATHS.walledGarden,
    exact: true,
    layout: Layout,
    component: Landing,
    pageTitle: 'Activate Your Internet Service',
    isWalledGarden: true,
  },
  {
    path: '*',
    layout: Layout,
    component: PageNotFound,
    pageTitle: 'Page Not Found',
  },
];

export default routes;
