import React from 'react';

export default function ComcastBusiness() {
  return (
    <symbol className="symbol-bsp-logo" id="icon-bsp-logo" viewBox="0 0 99 36">
      {/* -- Comcast --*/}
      <g>
        <path d="M22.2,13.7c-3.2,0-5.9-2.6-5.9-5.9c0-3.2,2.6-5.9,5.9-5.9s5.9,2.6,5.9,5.9C28.1,11,25.4,13.7,22.2,13.7 M22.2,0.5c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3C29.5,3.7,26.2,0.5,22.2,0.5" />
        <path d="M85,7.9c-0.4-0.2-2.8-1.4-3.1-1.6c-1.2-0.6-1.7-1.2-1.7-2.2c0-1.3,1.1-2.2,2.8-2.2c1,0,1.9,0.4,2.5,0.8 c0.1,0.1,0.2,0.1,0.3,0.1c0.4,0,0.7-0.3,0.7-0.7c0-0.3-0.1-0.5-0.4-0.6c-0.7-0.4-1.9-0.9-3.2-0.9c-2.5,0-4.2,1.5-4.2,3.6 c0,1.9,1.3,2.8,2.5,3.5c0.3,0.2,2.7,1.4,3.1,1.6c0.9,0.5,1.5,1.2,1.5,2.1c0,1.2-1,2.4-2.8,2.4s-3.1-1.2-3.5-1.5l-0.1-0.1l-1.1,1 l0.1,0.1c0.5,0.5,2.2,2,4.5,2c2.6,0,4.2-1.9,4.2-3.8C87.3,9.9,86.5,8.6,85,7.9" />
        <path d="M7.3,1.9c1.6,0,3,0.6,4.1,1.7c0.3,0.3,0.8,0.3,1,0c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5l-0.1-0.1 c-1.3-1.3-3.1-2-5-2C3.3,0.5,0,3.7,0,7.8c0,4,3.3,7.3,7.3,7.3c2.1,0,4-0.9,5.4-2.4l-1-1c-1.1,1.2-2.6,1.9-4.3,1.9 c-3.2,0-5.9-2.6-5.9-5.9C1.4,4.5,4.1,1.9,7.3,1.9" />
        <path d="M56.7,1.9c1.6,0,3,0.6,4.1,1.7c0.3,0.3,0.8,0.3,1,0C61.9,3.5,62,3.3,62,3.1s-0.1-0.4-0.2-0.5l-0.1-0.1 c-1.4-1.3-3.2-2.1-5.1-2.1c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3c2.1,0,4-0.9,5.4-2.4l-1-1c-1.1,1.2-2.6,1.9-4.3,1.9 c-3.2,0-5.9-2.6-5.9-5.9C50.8,4.5,53.4,1.9,56.7,1.9" />
        <path d="M44.3,0.5c-0.3,0-0.5,0.2-0.6,0.4s-4.3,10.6-4.3,10.6S35.2,1.1,35.1,0.9c-0.1-0.2-0.3-0.4-0.6-0.4 S33.9,0.7,33.9,1c-0.1,0.3-2.4,13.3-2.4,13.3v0.1c0,0.4,0.3,0.6,0.6,0.6s0.6-0.2,0.6-0.5l2-11.2c0,0,3.9,9.8,4,10s0.3,0.4,0.6,0.4 s0.5-0.2,0.6-0.4s4-10,4-10L46,14.9h1.3c0,0-2.5-13.7-2.6-14C44.9,0.7,44.6,0.5,44.3,0.5" />
        <path d="M68.1,8.1l2.2-5.3l2.2,5.3H68.1z M70.2,0.4c-0.3,0-0.5,0.2-0.6,0.4c-0.1,0.3-5.4,13.4-5.4,13.4 c0,0.1,0,0.2,0,0.3c0,0.4,0.3,0.6,0.6,0.6c0.3,0,0.5-0.2,0.6-0.4l2-5.1h5.5l2.2,5.4h1.4c0,0-5.6-13.9-5.7-14.1 C70.7,0.6,70.5,0.4,70.2,0.4L70.2,0.4z" />
        <path d="M98.6,1.3c0-0.4-0.3-0.8-0.7-0.8h-8.1c-0.4,0-0.8,0.4-0.8,0.8S89.3,2,89.7,2h3.4v13h1.3V2h3.5 C98.3,2,98.6,1.7,98.6,1.3" />
      </g>
      {/* -- Business --*/}
      <g fill="currentColor">
        <path d="M43.1,20.1c-0.7,0-1.2,0.5-1.2,1.1v13.4h2.3V21.3C44.3,20.7,43.8,20.1,43.1,20.1" />
        <path d="M16.8,28.9v-7.7c0-0.6-0.5-1.1-1.2-1.1s-1.2,0.5-1.2,1.1v7.6c0,3.3,2.3,5.9,5.9,5.9s5.9-2.6,5.9-5.9v-8.7 h-2.3v8.7c0,1.9-1.3,3.6-3.6,3.6C18,32.6,16.8,30.9,16.8,28.9" />
        <path d="M73,22.5c0.8,0,1.2-0.5,1.2-1.1c0-0.7-0.5-1.1-1.2-1.1h-7.1c-0.7,0-1.1,0.5-1.1,1.1v12.2 c0,0.6,0.5,1.1,1.1,1.1h8.3v-2.3h-7.1v-4.2h5.3c0.8,0,1.3-0.5,1.3-1.1c0-0.7-0.4-1.1-1.3-1.1h-5.3v-3.4L73,22.5L73,22.5z" />
        <path d="M6.7,32.5H3v-4.3h3.6c1.7,0,2.5,1,2.5,2.2C9.1,31.5,8.3,32.5,6.7,32.5L6.7,32.5z M3,22.5h3.5 c1.3,0,1.9,0.8,1.9,1.8s-0.7,1.8-1.9,1.8H3V22.5z M9.4,26.9c0.4-0.2,1.5-1.1,1.5-3c0-2.2-1.6-3.7-4-3.7h-5c-0.7,0-1.1,0.5-1.1,1.1 v13.3h6.4c2.6,0,4.4-1.9,4.4-4.3C11.5,27.8,9.8,27.1,9.4,26.9L9.4,26.9z" />
        <path d="M50,20.1c-0.7,0-1.2,0.5-1.2,1.1v12.4c0,0.6,0.5,1.1,1.2,1.1c0.6,0,1.2-0.5,1.2-1.1v-8.9c0,0,5.7,7.3,7.2,9.2 c0.4,0.5,0.6,0.9,1.3,0.9s1.1-0.5,1.1-1.1V20.2h-2.3v10c0,0-6.3-8.1-7.2-9.2C50.9,20.5,50.5,20.1,50,20.1" />
        <path d="M29,32.6c0.3,0.4,1.7,2.2,4.7,2.2c2.9,0,4.9-1.7,4.9-4.1c0-1.6-0.6-2.8-2.9-3.9c-0.6-0.3-2.3-1.1-2.3-1.1 c-0.9-0.4-1.3-1-1.3-1.7c0-0.8,0.6-1.6,2.1-1.6c0.9,0,1.5,0.3,1.9,0.4c0.2,0.1,0.6,0.3,1,0.3c0.5,0,1.1-0.4,1.1-1.1 c0-0.6-0.4-1-0.8-1.1c-0.6-0.3-1.6-0.7-3.2-0.7c-2.6,0-4.5,1.6-4.5,3.9c0,2.1,1.4,3.1,3.1,3.9c0.3,0.2,1.6,0.8,1.9,0.9 c1.1,0.5,1.6,1,1.6,1.9c0,1-0.9,1.8-2.3,1.8c-1.9,0-3-1.3-3.2-1.5L29,32.6z" />
        <path d="M76.5,32.6c0.3,0.4,1.7,2.2,4.7,2.2c2.9,0,4.9-1.7,4.9-4.1c0-1.6-0.6-2.8-2.9-3.9c-0.6-0.3-2.3-1.1-2.3-1.1 c-0.9-0.4-1.3-1-1.3-1.7c0-0.8,0.6-1.6,2.1-1.6c0.9,0,1.5,0.3,1.9,0.4c0.2,0.1,0.6,0.3,1,0.3c0.5,0,1.1-0.4,1.1-1.1 c0-0.6-0.4-1-0.8-1.1c-0.6-0.3-1.6-0.7-3.2-0.7c-2.6,0-4.5,1.6-4.5,3.9c0,2.1,1.4,3.1,3.1,3.9c0.3,0.2,1.6,0.8,1.9,0.9 c1,0.5,1.6,1,1.6,1.9c0,1-0.9,1.8-2.3,1.8c-2,0-3-1.3-3.2-1.5L76.5,32.6z" />
        <path d="M88.3,32.6c0.3,0.4,1.6,2.2,4.6,2.2c2.9,0,4.9-1.7,4.9-4.1c0-1.6-0.6-2.8-2.9-3.9c-0.6-0.3-2.4-1.1-2.4-1.1 c-0.9-0.4-1.3-1-1.3-1.7c0-0.8,0.6-1.6,2.1-1.6c0.9,0,1.5,0.3,1.9,0.4c0.2,0.1,0.6,0.3,1,0.3c0.5,0,1.1-0.4,1.1-1.1 c0-0.6-0.4-1-0.8-1.1c-0.6-0.3-1.6-0.7-3.2-0.7c-2.6,0-4.5,1.6-4.5,3.9c0,2.1,1.4,3.1,3.1,3.9c0.3,0.2,1.5,0.8,1.9,0.9 c1.1,0.5,1.6,1,1.6,1.9c0,1-0.9,1.8-2.3,1.8c-1.9,0-3-1.3-3.2-1.5L88.3,32.6z" />
      </g>
    </symbol>
  );
}
